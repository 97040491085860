
<template>
  <div>
    <v-card flat :loading="loading">
      <v-card-text>
        <v-row class="pa-3" align="center">
          <v-col cols="12" sm="6" class="mb-0 pb-0">
            <v-row>
              <v-col cols="12" class="mt-0 pt-0">
                <v-chip
                  class="mx-1"
                  small
                  outlined
                  v-for="(date, index) in quickSelectDates"
                  :key="index"
                  :value="date.value"
                  @click="selectDate(date.value)"
                  :color="params.selectedDate == date.value ? 'teal' : ''"
                >
                  <v-icon
                    left
                    v-if="params.selectedDate == date.value"
                    color="teal"
                    small
                    >check</v-icon
                  >
                  {{ date.text }}</v-chip
                >
                <v-menu
                  ref="menu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      color="blue"
                      class="mx-1"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      small
                      ><v-icon small left>date_range</v-icon>{{ dateText }}
                      <v-icon small right>expand_more</v-icon></v-chip
                    >
                  </template>
                  <v-date-picker
                    v-model="params.dates"
                    range
                    color="primary"
                    show-week
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary"> Cancel </v-btn>
                    <v-btn text color="primary" @click="setDateFilter">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <!-- <v-chip :key="dateKey" :color="params.dateType == 'ETD' ? 'teal' : 'blue-grey'" class="mx-1" small
                    @click="changeDateType()">
                    <v-icon small left>event</v-icon> Date Type:
                    {{ params.dateType }}
                  </v-chip> -->
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      small
                      class="primaryText--text mx-1"
                      color="blue-grey"
                      v-on="on"
                    >
                      <v-icon small left>event</v-icon> {{ params.dateType
                      }}<v-icon small right>expand_more</v-icon>
                    </v-chip>
                  </template>
                  <v-list style="overflow: hidden" dense>
                    <v-list-item
                      v-for="(mileType, index) in milestoneTypes"
                      :key="index"
                      @click="changeDateType(mileType.value)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ mileType.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-chip
                  id="team-select-one"
                  small
                  class="mx-1"
                  :color="
                    selectedTeams.includes(team.id) ? team.colour : 'darkgrey'
                  "
                  v-for="team in allTeams"
                  :key="team.id"
                  @click="selectTeam(team.id)"
                >
                  <v-icon small left v-if="selectedTeams.includes(team.id)"
                    >check</v-icon
                  >
                  {{ team.name }}</v-chip
                >
                <v-chip
                  id="team-select-all"
                  outlined
                  color="success"
                  small
                  v-if="selectedTeams.length < allTeams.length"
                  @click="selectAllTeams()"
                  >Select All</v-chip
                >
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>

          <v-btn
            v-if="settingsChange"
            color="blue"
            small
            rounded
            outlined
            @click="saveCustomHeaders()"
            :loading="loadingSaveCustom"
          >
            <v-icon small left>save</v-icon> Save Layout</v-btn
          >

          <v-col cols="12" sm="6" class="text-right mb-0 pb-0">
            <v-chip
              style="height: 40px"
              outlined
              small
              class="pr-1 text-center"
            >
              <!-- <v-menu offset-y rounded>
                  <template v-slot:activator="{ on }">
                    <v-btn style="text-transform: none" :color="importExport == 0 ? 'deep-orange' : 'blue darken-2'" rounded
                      v-on="on" small>
                      <div v-if="importExport == 0">
                        <v-icon small left>filter_alt</v-icon>
                        All Bookings
                      </div>
                      <div v-else-if="importExport == 1">
                        <v-icon small left>filter_alt</v-icon>
                        Exports
                      </div>
                      <div v-else-if="importExport == 2">
                        <v-icon small left>filter_alt</v-icon>
                        Imports
                      </div>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="changeImportExportFilter('ALL')">
                      <v-list-item-content>
                        <v-list-item-title> All Bookings </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="changeImportExportFilter('EXPORT')">
                      <v-list-item-content>
                        <v-list-item-title> Exports </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="changeImportExportFilter('IMPORT')">
                      <v-list-item-content>
                        <v-list-item-title> Imports </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu> -->

              <v-menu offset-y :disabled="selectedBookings.length == 0">
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    class="primaryText--text mx-1"
                    :color="selectedBookings.length > 0 ? 'blue' : 'grey'"
                    v-on="on"
                    style="border: none"
                  >
                    Actions<v-icon small right>expand_more</v-icon>
                  </v-chip>
                </template>
                <v-list style="overflow: hidden" dense>
                  <!-- <v-list-item style="height:35px" @click="bulkUpdateStatusModal = true">
                                <v-list-item-action>
                                    <v-icon color="grey">fiber_manual_record</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Update Status
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->

                  <v-list-item
                    style="height: 35px"
                    @click="downloadBookingData"
                  >
                    <v-list-item-action>
                      <v-icon color="grey">save_alt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Download Booking Data
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-chip small class="mr-1 pr-3">TOTAL:
                  <span style="padding-right: 5px; margin-left: 5px">
                    {{ bookings.bookingCount }}</span>
                </v-chip> -->
              <v-chip color="blue-grey" small class="mr-1 pr-3">
                <span style="padding-right: 10px">
                  {{ filteredResults.length }} Containers</span
                >
              </v-chip>

              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-menu
                      offset-y
                      nudge-right
                      :close-on-content-click="false"
                      style="height: 20vh; margin-right: 8vw"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon
                          ><v-icon>view_week</v-icon></v-btn
                        >
                      </template>
                      <v-card
                        style="
                          background: var(--v-modal-base) !important;
                          border-radius: 20px;
                        "
                      >
                        <v-card-text>
                          <v-list
                            style="
                              overflow: hidden;
                              column-count: 3;
                              width: 400px;
                              height: fit-content;
                            "
                          >
                            <v-checkbox
                              v-for="(item, index) in headers.filter(
                                (x) => x.value != 'action'
                              )"
                              :key="index"
                              v-model="item.show"
                              @change="toggleColumn(item)"
                              dense
                              :label="item.text"
                              hide-details
                            ></v-checkbox>
                          </v-list>
                          <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                            <v-col
                              cols="12"
                              md="12"
                              sm="12"
                              lg="12"
                              class="d-flex justify-center"
                            >
                              <v-btn
                                @click="saveCustomHeaders"
                                :loading="loadingSaveCustom"
                                outlined
                                color="primary"
                                >SAVE</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                Customize Layout
              </v-tooltip>
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
            </v-chip>
            <v-row class="my-3" :key="updateFilterValue">
              <div :key="updateFilterValue">
                <v-chip
                  color="red"
                  class="mx-1"
                  small
                  @click="resetFilters()"
                  v-if="filteredValues.length > 0"
                >
                  <v-icon small left>filter_alt_off</v-icon> Clear Filters
                </v-chip>
                <v-chip
                  class="mx-1"
                  close
                  small
                  @click:close="
                    addToFilter(filterValue.value, filterValue.field)
                  "
                  v-for="(filterValue, index) in filteredValues"
                  :key="index"
                  >{{ filterValue.value }}</v-chip
                >
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0">
          <div :style="{ width: tableWidth }" v-if="displayData.length > 0">
            <table :width="'100%'">
              <tr class="mx-0 px-0">
                <draggable
                  v-model="selectedHeaders"
                  tag="tr"
                  :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                  :animation="200"
                  @change="updateUserLayout()"
                  ghost-class="ghost-card"
                  @start="columnDrag = true"
                  @end="columnDrag = false"
                >
                  <td
                    class="text-center mx-0 px-0"
                    style="
                      cursor: grab;
                      border-right: 1px solid grey;
                      min-height: 20px;
                      min-width: 20px;
                    "
                    v-for="(header, index) in selectedHeaders"
                    :key="index"
                    :width="header.width ?? '150px'"
                  >
                    <div>
                      <v-row align="center" justify="center" class="py-2">
                        <v-checkbox
                          v-if="header.value == 'actions'"
                          :indeterminate="
                            selectedBookings.length > 0 &&
                            selectedBookings.length < filteredResults.length
                          "
                          hide-details
                          v-model="selectAllBookings"
                          dense
                          class="mx-0 px-0 mb-2"
                        ></v-checkbox>
                        <b style="font-size: 12px">{{ header.text }}</b>
                        <v-btn
                          v-if="!header.disableSort"
                          class="ml-1"
                          icon
                          x-small
                          @click="changeSortBy(header.value)"
                        >
                          <v-icon
                            v-if="sortBy != header.value"
                            small
                            color="grey"
                            >arrow_upward</v-icon
                          >
                          <v-icon v-else-if="sortDesc" small
                            >arrow_downward</v-icon
                          >
                          <v-icon v-else small>arrow_upward</v-icon>
                        </v-btn>
                        <v-badge
                          v-if="!header.disableFilter"
                          :content="
                            filters[header.value]
                              ? filters[header.value].length
                              : false
                          "
                          :value="
                            filters[header.value] &&
                            filters[header.value].length > 0
                          "
                          color="primary"
                          offset-y="10"
                        >
                          <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                color="grey"
                                icon
                                ><v-icon small>filter_alt</v-icon></v-btn
                              >
                            </template>
                            <v-card
                              style="
                                background: var(--v-modal-base) !important;
                                border-radius: 20px;
                              "
                            >
                              <v-card-text>
                                <v-list
                                  dense
                                  style="
                                    background: var(--v-modal-base) !important;
                                    border-radius: 20px;
                                  "
                                >
                                  <v-text-field
                                    hide-details
                                    rounded
                                    dense
                                    outlined
                                    clearable
                                    prepend-inner-icon="search"
                                    placeholder="Search"
                                    autofocus
                                    v-model="searchHeaders[header.value]"
                                    @input="updateSearchKey()"
                                  ></v-text-field>
                                  <v-list
                                    dense
                                    style="
                                      max-height: 50vh;
                                      overflow-y: auto;
                                      background: var(
                                        --v-modal-base
                                      ) !important;
                                    "
                                    :key="searchKey"
                                  >
                                    <v-list-item
                                      v-for="(item, index) in headerOptions(
                                        header.value
                                      )"
                                      :key="index"
                                    >
                                      <v-list-item-action class="pr-0">
                                        <v-checkbox
                                          v-model="filters[header.value]"
                                          :value="item.value"
                                          hide-details
                                          dense
                                          @change="calculateFilteredResults()"
                                        ></v-checkbox>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.name }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="
                                        headerOptions(header.value).length == 0
                                      "
                                    >
                                      <v-list-item-content class="text-center">
                                        <span style="font-size: 12px"
                                          >No results found</span
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </v-badge>
                      </v-row>
                    </div>
                  </td>
                </draggable>
              </tr>
            </table>
            <v-divider></v-divider>
            <div
              class="mt-1 pt-1 px-1"
              :style="{ width: '100%' }"
              v-if="displayData.length > 0"
            >
              <v-virtual-scroll
                v-if="displayData.length > 0"
                :bench="10"
                :items="displayData"
                :height="calculatedHeight"
                :width="'100%'"
                item-height="35"
                class="px-1"
              >
                <template v-slot:default="{ item }">
                  <div
                    class="text-center my-0 py-0"
                    :style="{
                      display: 'flex',
                      'flex-direction': 'row',
                      width: '100%',
                      'background-color':
                        selectedBookingItem && selectedBookingItem.id == item.id
                          ? 'rgba(12, 97, 24, 0.6)'
                          : 'transparent',
                          cursor: 'pointer'
                    }"
                    @click="selectContainer(item)"
                  >
                    <td
                      v-for="(header, index) in selectedHeaders"
                      :key="index"
                      :style="{ width: header.width ?? '150px' }"
                      class="text-center mt-0 mb-0 pb-0"
                    >
                      <div v-if="header.value == 'actions'">
                        <v-row align="center" justify="center" class="py-1">
                          <v-checkbox
                            hide-details
                            dense
                            :value="
                              selectedBookings
                                .map((x) => x.id)
                                .includes(item.id)
                            "
                            @change="selectBooking(item)"
                            class="mb-2 mx-0 px-0"
                          ></v-checkbox>
                          <!-- <v-btn icon @click="viewBooking(item)" small>
                                <v-icon small>launch</v-icon>
                              </v-btn> -->
                          <v-tooltip top v-if="item.booking">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                icon
                                color="teal"
                                v-on="on"
                                @click="viewBooking(item.booking)"
                              >
                                <v-icon small>confirmation_number</v-icon>
                              </v-btn>
                            </template>
                            View booking {{ item.booking.orderNo }}
                          </v-tooltip>

                          <v-tooltip top v-if="item.shipmentFile">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                icon
                                color="indigo"
                                v-on="on"
                                @click="viewShipmentFile(item.shipmentFile)"
                              >
                                <v-icon small>description</v-icon>
                              </v-btn>
                            </template>
                            View file {{ item.shipmentFile.fileNumber }}
                          </v-tooltip>
                        </v-row>
                      </div>
                      <div v-if="header.value == 'state'" class="text-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :color="statusColor(item.state)"
                              small
                              icon
                              v-on="on"
                              @click="addToFilter(item.state, 'state')"
                            >
                              <v-icon>{{ statusIcon(item.state) }}</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 12px">{{
                            $Format.capitalizeFirstLetter(item.state)
                          }}</span>
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'userFullName'"
                        class="text-center"
                      >
                        <v-tooltip top v-if="item.booking.user">
                          <template v-slot:activator="{ on }">
                            <v-avatar
                              style="cursor: pointer"
                              size="24"
                              class="ml-1"
                              v-on="on"
                              :color="
                                item.booking.user.avatar ? 'white' : 'secondary'
                              "
                              @click="
                                addToFilter(
                                  item.booking.user.firstname,
                                  'userFullName'
                                )
                              "
                            >
                              <v-img
                                v-if="item.booking.user.avatar"
                                contain
                                :src="item.booking.user.avatar"
                              ></v-img>
                              <h4 v-else>
                                {{ item.booking.user.firstname.charAt(0) }}
                              </h4>
                            </v-avatar>
                          </template>
                          <span style="font-size: 12px"
                            >{{ item.booking.user.firstname }}
                            {{ item.booking.user.surname }}</span
                          >
                        </v-tooltip>
                      </div>

                      <div
                        v-else-if="header.value == 'orderNo'"
                        class="text-center"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-chip
                              outlined
                              style="border: none; font-size: 11px"
                              class="primaryText--text"
                              small
                              v-on="on"
                              @click="addToFilter(item.orderNo, 'orderNo')"
                            >
                              <v-btn
                                left
                                :color="bookingTypeColor(item.type)"
                                small
                                icon
                              >
                                <v-icon>{{
                                  bookingTypeIcon(item.type)
                                }}</v-icon>
                              </v-btn>
                              {{ item.orderNo }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
                            $Format.capitalizeFirstLetter(item.booking.type)
                          }}</span>
                        </v-tooltip>
                      </div>

                      <div
                        v-else-if="header.value == 'vesselVoyage'"
                        class="text-left"
                      >
                        <v-tooltip top v-if="item.vesselVoyage">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-on="on"
                              small
                              class="primaryText--text"
                              outlined
                              @click="
                                addToFilter(item.vesselVoyage, 'vesselVoyage')
                              "
                              style="border: none; font-size: 11px"
                            >
                              <v-icon
                                small
                                left
                                v-if="item.isTransshipment"
                                color="orange"
                                >airline_stops</v-icon
                              >
                              <v-icon v-else small left color="grey"
                                >navigation</v-icon
                              >
                              <span>{{ item.vesselVoyage }}</span>
                            </v-chip>
                          </template>
                          <span
                            >{{ item.vesselVoyage }}:
                            <span v-if="item.isTransshipment"
                              >Transshipment</span
                            >
                            <span v-else>Direct</span>
                          </span>
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'features'"
                        class="text-center"
                      >
                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Genset'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#D6D46D"
                              ><v-icon small>bolt</v-icon></v-btn
                            >
                          </template>
                          <span>Genset Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Fumigation'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#F1DBBF"
                              ><v-icon small>cleaning_services</v-icon></v-btn
                            >
                          </template>
                          <span>Fumigations Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Void Plugs'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#e05b0d"
                              ><v-icon small>power</v-icon></v-btn
                            >
                          </template>
                          <span>Void Plugs</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Blast Freezing'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#13a7d4"
                              ><v-icon small>ac_unit</v-icon></v-btn
                            >
                          </template>
                          <span>Blast Freezing</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) =>
                                product.product === 'Monitoring Service'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#99B080"
                              ><v-icon small>desktop_windows</v-icon></v-btn
                            >
                          </template>
                          <span>
                            {{
                              item.temporaryProducts.find(
                                (product) =>
                                  product.product === "Monitoring Service"
                              ).variation +
                              " " +
                              "Service" +
                              " " +
                              "Required"
                            }}
                          </span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'ContainerLiners'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#EEF296"
                              ><v-icon small>settings_overscan</v-icon></v-btn
                            >
                          </template>
                          <span>
                            {{ "Container Liners" + " " + "Required" }}
                          </span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'SmartFresh'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#FFBFFF"
                              ><v-icon small>smart_screen</v-icon></v-btn
                            >
                          </template>
                          <span>
                            {{ "Smart Fresh" + " " + "Required" }}
                          </span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'SteriInsurance'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#EEF296"
                              ><v-icon small>emergency</v-icon></v-btn
                            >
                          </template>
                          <span>
                            {{ "Steri Insurance" + " " + "Required" }}
                          </span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'TelemPlus'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#18e00d"
                              ><v-icon small>router</v-icon></v-btn
                            >
                          </template>
                          <span>
                            Devices Required:
                            <template
                              v-if="
                                item.temporaryProducts.some(
                                  (product) => product.product === 'TelemPlus'
                                )
                              "
                            >
                              <ul>
                                <li
                                  v-for="(
                                    [variation, count], index
                                  ) in Object.entries(
                                    item.temporaryProducts
                                      .filter(
                                        (product) =>
                                          product.product === 'TelemPlus'
                                      )
                                      .reduce((acc, product) => {
                                        if (!acc[product.variation]) {
                                          acc[product.variation] = 0;
                                        }
                                        acc[product.variation] += product.count;
                                        return acc;
                                      }, {})
                                  )"
                                  :key="index"
                                >
                                  {{ count }}X{{ variation }}
                                </li>
                              </ul>
                            </template>
                          </span>
                        </v-tooltip>

                        <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Otflow' ) " >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#6A9C89"
                              ><v-icon small>air</v-icon></v-btn
                            >
                          </template>
                          <span>Otflow Required</span>
                        </v-tooltip>


                        <v-tooltip top v-if=" item.temporaryProducts.some( (product) => product.product === 'Zoono' ) " >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#5b93c7"
                              ><v-icon small>sanitizer</v-icon></v-btn
                            >
                          </template>
                          <span>Zoono Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Dunnage Bags'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#F1DBBF"
                              ><v-icon small>shopping_bag</v-icon></v-btn
                            >
                          </template>
                          <span>Dunnage Bags Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Late Arrival'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#BB2525"
                              ><v-icon small>watch_later</v-icon></v-btn
                            >
                          </template>
                          <span
                            >Late Arrival Required :
                            {{
                              item.temporaryProducts.find(
                                (product) => product.product === "Late Arrival"
                              ).variation
                            }}</span
                          >
                        </v-tooltip>

                        <!--            -->
                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Latches'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#508D69"
                              ><v-icon small>pivot_table_chart</v-icon></v-btn
                            >
                          </template>
                          <span>Latches Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Ratches'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#EA906C"
                              ><v-icon small
                                >settings_input_component</v-icon
                              ></v-btn
                            >
                          </template>
                          <span>Ratches Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Stabling bars'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#5F6F52"
                              ><v-icon small>video_stable</v-icon></v-btn
                            >
                          </template>
                          <span>Stabling bars Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Kraft paper'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#C5E898"
                              ><v-icon small>note</v-icon></v-btn
                            >
                          </template>
                          <span>Kraft paper Required</span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="
                            item.temporaryProducts.some(
                              (product) => product.product === 'Absorb Bags'
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              outlined
                              small
                              class="mx-1"
                              v-on="on"
                              color="#DCBFFF"
                              ><v-icon small>backpack</v-icon></v-btn
                            >
                          </template>
                          <span>Absorb Bags Required</span>
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'portOfLoadValue'"
                        class="text-center"
                      >
                        <v-tooltip top v-if="item.portOfLoadValue">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              outlined
                              style="border: none; font-size: 11px"
                              v-on="on"
                              small
                              @click="
                                addToFilter(
                                  item.portOfLoadValue,
                                  'portOfLoadValue'
                                )
                              "
                            >
                              <v-avatar size="20" left>
                                <v-img
                                  contain
                                  :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                    .substring(0, 2)
                                    .toLowerCase()}.svg`"
                                ></v-img>
                              </v-avatar>
                              {{ item.portOfLoadValue }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
                            item.portOfLoadCity
                          }}</span>
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'finalDestinationValue'"
                        class="text-center"
                      >
                        <v-tooltip top v-if="item.finalDestinationValue">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              outlined
                              style="border: none; font-size: 11px"
                              v-on="on"
                              small
                              @click="
                                addToFilter(
                                  item.finalDestinationValue,
                                  'finalDestinationValue'
                                )
                              "
                            >
                              <v-avatar size="20" left>
                                <v-img
                                  contain
                                  :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                    .substring(0, 2)
                                    .toLowerCase()}.svg`"
                                ></v-img>
                              </v-avatar>
                              {{ item.finalDestinationValue }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
                            item.finalDestinationCity
                          }}</span>
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'isConfirmedBooking'"
                        class="text-center"
                      >
                        <v-btn
                          small
                          icon
                          :color="item.isConfirmedBooking ? 'success' : 'red'"
                          @click="
                            addToFilter(
                              item.isConfirmedBooking,
                              'isConfirmedBooking'
                            )
                          "
                        >
                          <v-icon v-if="item.isConfirmedBooking" small
                            >check_circle_outline</v-icon
                          >
                          <v-icon v-else color="red" small>cancel</v-icon>
                        </v-btn>
                      </div>

                      <div
                        v-else-if="header.value == 'preadvise'"
                        class="text-center"
                      >
                        <v-btn
                          small
                          icon
                          :color="item.preadvise ? 'success' : 'red'"
                          @click="addToFilter(item.preadvise, 'preadvise')"
                        >
                          <v-icon v-if="item.preadvise" small
                            >check_circle_outline</v-icon
                          >
                          <v-icon v-else color="red" small>cancel</v-icon>
                        </v-btn>
                      </div>

                      <div
                        v-else-if="header.value == 'consigneeName'"
                        class="text-left"
                      >
                        <v-tooltip top v-if="item.consignee">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-if="item.consignee"
                              small
                              class="primaryText--text"
                              outlined
                              @click="
                                addToFilter(item.consigneeName, 'consigneeName')
                              "
                              v-on="on"
                              style="border: none"
                            >
                              <v-avatar
                                size="24"
                                :color="
                                  item.consignee && item.consignee.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                left
                              >
                                <v-img
                                  style=""
                                  v-if="item.consignee.logo"
                                  :src="item.consignee.logo"
                                  contain
                                ></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.consigneeName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.consigneeName }}
                            </v-chip>
                          </template>
                          <span>{{ item.consignee.name }}</span>
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'transporterName'"
                        :class="item.transporter ? 'text-left' : 'text-center'"
                      >
                        <v-tooltip top v-if="item.transporter">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-if="item.transporter"
                              small
                              class="primaryText--text"
                              v-on="on"
                              outlined
                              @click="
                                addToFilter(
                                  item.transporterName,
                                  'transporterName'
                                )
                              "
                              style="border: none"
                            >
                              <v-avatar
                                size="20"
                                :color="
                                  item.transporter && item.transporter.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                left
                              >
                                <v-img
                                  v-if="item.transporter.logo"
                                  :src="item.transporter.logo"
                                  contain
                                ></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.transporterName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.transporterName }}
                            </v-chip>
                          </template>
                          <span>{{ item.transporter.name }}</span>
                        </v-tooltip>
                        <v-btn
                          color="grey"
                          small
                          icon
                          v-else
                          @click="assignContainers([item])"
                          ><v-icon small>add_circle_outline</v-icon></v-btn
                        >
                      </div>

                      <div
                        v-else-if="header.value == 'customerName'"
                        class="text-left"
                      >
                        <v-tooltip top v-if="item.customer">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-if="item.customer"
                              small
                              class="primaryText--text"
                              v-on="on"
                              outlined
                              @click="
                                addToFilter(item.customerName, 'customerName')
                              "
                              style="border: none"
                            >
                              <v-icon
                                class="mr-2"
                                small
                                left
                                v-if="item.clientStatus"
                                :color="getClientStatusColor(item.clientStatus)"
                                >fiber_manual_record</v-icon
                              >
                              <v-avatar
                                size="20"
                                :color="
                                  item.customer && item.customer.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                left
                              >
                                <v-img
                                  v-if="item.customer.logo"
                                  :src="item.customer.logo"
                                  contain
                                ></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.customerName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.customerName }}
                            </v-chip>
                          </template>
                          <span
                            >{{ item.customer.name }}
                            <span v-if="item.clientStatus">
                              - Status: {{ item.clientStatus }}</span
                            ></span
                          >
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'shipperName'"
                        class="text-left"
                      >
                        <v-tooltip top v-if="item.shipper">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-if="item.shipper"
                              small
                              class="primaryText--text"
                              v-on="on"
                              outlined
                              @click="
                                addToFilter(item.shipperName, 'shipperName')
                              "
                              style="border: none"
                            >
                              <v-avatar
                                size="20"
                                :color="
                                  item.shipper && item.shipper.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                left
                              >
                                <v-img
                                  v-if="item.shipper.logo"
                                  :src="item.shipper.logo"
                                  contain
                                ></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.shipperName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.shipperName }}
                            </v-chip>
                          </template>
                          <span>{{ item.shipper.name }}</span>
                        </v-tooltip>
                      </div>

                      <div
                        v-else-if="header.value == 'shippingLineName'"
                        class="text-left"
                      >
                        <v-chip
                          v-if="item.shippingLine"
                          small
                          class="primaryText--text"
                          outlined
                          @click="
                            addToFilter(
                              item.shippingLineName,
                              'shippingLineName'
                            )
                          "
                          style="border: none"
                        >
                          <v-avatar
                            size="24"
                            :color="
                              item.shippingLine && item.shippingLine.logo
                                ? 'white'
                                : 'secondary'
                            "
                            left
                          >
                            <v-img
                              v-if="item.shippingLine.logo"
                              :src="item.shippingLine.logo"
                              contain
                            ></v-img>
                            <h3 v-else style="color: white">
                              {{ item.shippingLineName.charAt(0) }}
                            </h3>
                          </v-avatar>
                          {{ item.shippingLineName }}
                        </v-chip>
                      </div>
                      <div v-else-if="header.value == 'transportStatus'">
                        <!-- <v-menu offset-y v-if="item[header.value]">
                            <template v-slot:activator="{ on }">
                              <v-chip small class="primaryText--text" :color="getStatusColor(item.transportStatus)"
                                v-on="on" style="border: none">
                                {{ item[header.value] }}
                                <v-icon small right>expand_more</v-icon>
                              </v-chip>
                            </template>
                            <v-list style="overflow: hidden" dense>
                              <v-list-item v-for="(status, index) in transportStatuses" :key="index" style="height: 35px"
                                @click="setStatus(item, status)">
                                <v-list-item-action>
                                  <v-icon :color="status.color">fiber_manual_record</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ status.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu> -->
                        <v-chip
                          v-if="item[header.value]"
                          small
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          :color="transportStatusColor(item[header.value])"
                        >
                          {{ item[header.value] }}
                        </v-chip>
                      </div>

                      <div v-else-if="header.value == 'status'">
                        <v-chip
                          v-if="item[header.value]"
                          small
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          :color="containerStatusColor(item[header.value])"
                        >
                          {{ item[header.value] }}
                        </v-chip>
                      </div>

                      <div v-else-if="header.value == 'loadingDate'">
                        <v-chip
                          v-if="item[header.value]"
                          small
                          class="primaryText--text"
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          style="border: none"
                        >
                          {{ formatDate(item[header.value]) }}
                        </v-chip>
                      </div>
                      <div v-else-if="header.value == 'etd'">
                        <v-chip
                          v-if="item[header.value]"
                          small
                          class="primaryText--text"
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          style="border: none"
                        >
                          {{ formatDate(item[header.value]) }}
                        </v-chip>
                      </div>
                      <!-- <div v-else-if="header.value == 'vesselLegs'">
                          <v-btn v-if="item.sailingSchedule" icon small outlined @click="viewBookingLegs(item)" :color="item.sailingSchedule.legs == 1 ? 'success' : 'blue'
                            ">{{ item.sailingSchedule.legs }}</v-btn>
                        </div>
                        <div v-else-if="header.value == 'numContainers'">
                          <v-chip v-if="item[header.value]" small @click="addToFilter(item[header.value], header.value)">
                            {{ item[header.value] }}
                          </v-chip>
                        </div> -->
                      <!-- <div v-else-if="header.value == 'shipmentFileTotals'">
                              <template >
                                <v-chip
                                  small
                                  icon
                                  outlined
                                  @click="addToFilter(entry, header.value)"
                                  :color="checkFileStatus(entry) === 'Done' ? 'success' : 'orange'"
                        
                                >
                                  {{ checkFileStatus(entry) }}
                                </v-chip>
                              </template>
                          
                  
                          </div> -->
                      <!-- <div v-else-if="header.value == 'shipmentFileTotals'">
                          <v-tooltip top v-if="item[header.value].length > 0">
                            <template v-slot:activator="{ on }">
                              <v-chip v-if="item[header.value]" small icon outlined @click="
                                addToFilter(item[header.value], header.value)
                                " :color="checkFileStatus(item[header.value]) === 'READY'
        ? 'success'
        : 'orange'
      " v-on="on">
                                {{ checkFileStatus(item[header.value]) }}
                              </v-chip>
                            </template>
                            <span v-for="(item, index) in popupFileStauts(
                              item[header.value]
                            )" :key="index" :style="{ color: item.color }">
                              {{ item.text }}<br />
                            </span>
                          </v-tooltip>
        
                        </div> -->

                      <div v-else-if="header.value == 'eta'">
                        <v-chip
                          v-if="item[header.value]"
                          small
                          class="primaryText--text"
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          style="border: none"
                        >
                          {{ formatDate(item[header.value]) }}
                        </v-chip>
                      </div>

                      <div v-else-if="header.value == 'movementType'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-on="on"
                              small
                              class="primaryText--text"
                              outlined
                              @click="
                                addToFilter(item.movementType, header.value)
                              "
                              style="border: none"
                            >
                              <v-avatar
                                :color="
                                  item.movementType == 'EXPORT'
                                    ? 'deep-orange'
                                    : 'blue'
                                "
                                left
                              >
                                <h4 style="color: white">
                                  {{ item.movementType.charAt(0) }}
                                </h4>
                              </v-avatar>
                              {{ item.orderNo }}
                              <v-icon
                                small
                                right
                                color="grey"
                                v-if="
                                  item.organisationId !=
                                  $store.state.currentOrg.id
                                "
                                >domain_disabled</v-icon
                              >
                            </v-chip>
                          </template>
                          <span>
                            <span
                              v-if="
                                item.organisationId !=
                                $store.state.currentOrg.id
                              "
                              >EXTERNAL:
                            </span>
                            {{ $Format.capitalizeFirstLetter(item.movementType) }}
                            Booking
                          </span>
                        </v-tooltip>
                      </div>

                      <div v-else>
                        <v-chip
                          v-if="item[header.value]"
                          small
                          class="primaryText--text"
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          style="border: none"
                        >
                          {{ item[header.value] }}
                        </v-chip>
                      </div>
                    </td>
                  </div>

                  <v-divider class="my-0 py-0"></v-divider>
                </template>
              </v-virtual-scroll>
            </div>
          </div>
          <div class="container" v-if="!loading && displayData.length == 0">
            <h3 style="color: grey">No Results Found</h3>
          </div>
          <div class="container" v-else-if="loading && displayData.length == 0">
            <valhalla-loading height="500px" />
          </div>
        </v-card-text>
      </v-card-text>
    </v-card>

    <!-- <v-menu
          v-model="showMenu"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-list dense style="background: var(--v-modal-base) !important">
            <v-subheader v-if="selectedBookingItem" style="font-size: 14px"
              ><v-icon class="mr-2" color="grey">widgets</v-icon>
              {{ selectedBookingItem.orderNo }}</v-subheader
            >
            <v-divider></v-divider>
    
            <v-list-item @click="viewBooking(selectedBookingItem)">
              <v-list-item-action class="mr-1 pr-1">
                <v-icon small color="grey">launch</v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-left ml-0 pl-0">
                <v-list-item-title>View Booking</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="cloneBooking(selectedBookingItem)">
              <v-list-item-action class="mr-1 pr-1">
                <v-icon small color="grey">content_copy</v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-left ml-0 pl-0">
                <v-list-item-title>Clone Booking</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loadingClone"
                  size="20"
                ></v-progress-circular>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="selectBooking(selectedBookingItem)">
              <v-list-item-action class="mr-1 pr-1">
                <v-icon small color="grey">check</v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-left ml-0 pl-0">
                <v-list-item-title>Select Booking</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->

    <!-- <v-dialog
          v-model="createBookingModal"
          width="90vw"
          :fullscreen="$vuetify.breakpoint.mobile"
          persistent
        >
          <BookingModal
            :key="refreshBooking"
            :bookingObj="booking"
            :related="organisations"
            :relatedLoading="loadingOrganisations"
            @close="(createBookingModal = false), (booking = {})"
          />
        </v-dialog> -->
    <!-- <v-dialog v-model="selectedBookingModal" width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
          <v-toolbar flat color="transparent">
            <v-toolbar-title>
              {{ selectedBooking.orderNo }}
            </v-toolbar-title>
            <v-toolbar-title v-if="selectedBooking.sailingSchedule">
              {{ selectedBooking.sailingSchedule.vesselDescription }} -
              {{ selectedBooking.sailingSchedule.voyage }}
              <v-chip small outlined style="border: none" class="ml-2">
                <v-avatar size="22" left>
                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${selectedBooking.sailingSchedule.portOfLoadValue
                    .substring(0, 2)
                    .toLowerCase()}.svg`"></v-img>
                </v-avatar>
                {{ selectedBooking.sailingSchedule.portOfLoadValue }}
              </v-chip>
              <v-icon small class="mx-1">arrow_forward</v-icon>
              <v-chip small outlined style="border: none">
                <v-avatar size="22" class="ml-1" left>
                  <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${selectedBooking.sailingSchedule.finalDestinationValue
                    .substring(0, 2)
                    .toLowerCase()}.svg`"></v-img>
                </v-avatar>
                {{ selectedBooking.sailingSchedule.finalDestinationValue }}
              </v-chip>
              <v-chip class="ml-2">
                {{ selectedBooking.sailingSchedule.transit }} Day(s)
              </v-chip>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text @click="selectedBookingModal = false">X</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" class="mt-0 pt-1">
                <v-list dense subheader v-if="selectedBooking.sailingSchedule">
                  <v-subheader>Vessel Legs</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="selectedBooking.sailingSchedule.sailingScheduleLegs
                      .length == 0
                    ">
                    <v-list-item-content>
                      <span style="color: grey; font-size: 12px">No Vessel Legs</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="leg in selectedBooking.sailingSchedule
                    .sailingScheduleLegs" :key="leg.id">
                 
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip small class="mr-1">{{ leg.leg }}</v-chip>
                        <v-chip small outlined style="border: none">
                          <v-avatar size="20" left>
                            <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                          {{ leg.portOfLoadValue }}
                          <v-icon small class="mx-1">arrow_forward</v-icon>
                          <v-avatar size="20" class="ml-1" left>
                            <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                          {{ leg.portOfDischargeValue }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon small color="blue" class="mr-2">schedule</v-icon>
                        {{ formatDateTime(leg.etd) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon small color="green" class="mr-2">schedule</v-icon>
                        {{ formatDateTime(leg.eta) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="leg.vesselDescription">
                        <v-icon small color="grey" class="mr-2">directions_boat</v-icon>
                        {{ leg.vesselDescription }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="leg.voyage">
                        <v-icon small color="grey" class="mr-2">tag</v-icon>
                        {{ leg.voyage }}
                      </v-list-item-subtitle>
                   
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" sm="9" v-if="selectedBooking.bookingContainers">
                <v-data-table :items="selectedBooking.bookingContainers" :loading="loadingContainers" dense
                  disable-pagination hide-default-footer :headers="containerHeaders"
                  style="background-color: var(--v-modal-base) !important">
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip small class="white--text" :color="containerStatusColor(item)" style="font-weight: bold">
                      <span v-if="item.isDeleted">Cancelled</span>
                      <span v-else-if="item.status">{{ item.status }}</span>
                      <span v-else>Unallocated</span>
                    </v-chip>
                  </template>
                  <template v-slot:[`item.features`]="{ item }">
                    <v-tooltip top v-if="item.gensetRequired">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#c4c166"><v-icon
                            small>bolt</v-icon></v-btn>
                      </template>
                      <span>Genset Required</span>
                    </v-tooltip>
  
                    <v-tooltip top v-if="item.caProduct">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="teal"><v-icon
                            small>thermostat_auto</v-icon></v-btn>
                      </template>
                      <span>Controlled Atmosphere: {{ item.caProduct }}</span>
                    </v-tooltip>
  
                    <v-tooltip top v-if="item.sensorDevice">
                      <template v-slot:activator="{ on }">
                        <v-btn icon outlined small class="mx-1" v-on="on" color="#c45d1d"><v-icon
                            small>router</v-icon></v-btn>
                      </template>
                      <span>Monitoring Device:
                        <span v-if="item.sensorDevice">
                          {{ item.sensorDeviceType }}
                          <span v-if="item.sensorService">
                            with {{ item.sensorService }}
                            service
                          </span>
                          <span v-else> without a service </span>
                        </span>
                        <span v-else>None</span>
                      </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog> -->

    <v-dialog
      v-model="previewShipmentFile"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="previewShipmentFile && selectedFile && selectedFile.id"
        :fileId="selectedFile.id"
        @close="previewShipmentFile = false"
      />
    </v-dialog>

   <v-dialog v-model="containerModal" width="90vw">
    <v-card flat v-if="selectedContainer && selectedContainer.id">
                      <v-card-title>
                        <v-icon class="mr-2" color="primary">widgets</v-icon>
                        Container Overview: <span class="ml-1" v-if="selectedContainer.containerNo">{{ selectedContainer.containerNo }}</span> 
                        <span class="ml-1" v-else>{{ selectedContainer.ctoNo }}</span>
                        
                      </v-card-title>
                      <v-progress-linear
                        class="mt-1"
                        v-if="loadingContainer"
                        color="primary"
                        indeterminate
                      ></v-progress-linear>
                      <v-card flat v-if="selectedContainer && selectedContainer.id">
                        <v-card-text class="mt-0 pt-0">
                          <v-row>
                            <v-col cols="12" sm="3" md="2">
                              <v-card
                                flat
                                style="
                                  background-color: var(
                                    --v-component-base
                                  ) !important;
                                "
                              >
                                <v-card-text>
                                  <v-list
                                    dense
                                    subheader
                                  >
                                    <v-subheader style="font-size: 16px"
                                      >Details</v-subheader
                                    >
                                    <v-divider></v-divider>
                                    <v-list-item v-if="selectedContainer.containerNo">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >tag</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ selectedContainer.containerNo }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                          v-if="selectedContainer.shipmentFile"
                                        >
                                          {{
                                            !selectedContainer.shipmentFile.breakBulkShipment
                                              ? "Container No."
                                              : "Deck No."
                                          }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="selectedContainer.ctoNo">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >description</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ selectedContainer.ctoNo }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          CTO Number
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="selectedContainer.sealNo">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >label</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ selectedContainer.sealNo }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          Seal Number
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="selectedContainer && selectedContainer.containerPallets">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >scale</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ containerGrossWeight(selectedContainer) }} KGS
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          Gross Weight
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="selectedContainer && selectedContainer.containerPallets">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >scale</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ containerNettWeight(selectedContainer) }} KGS
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          Nett Weight
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider> </v-divider>
                                    <v-subheader style="font-size: 16px">
                                      Commodities</v-subheader
                                    >
                                    <v-divider></v-divider>
                                    <v-list-item
                                      v-for="(
                                        product
                                      ) in containerProducts(selectedContainer)"
                                      :key="product.productId"
                                    >
                                      <!-- <v-list-item-action>
                                        <v-chip>{{ index + 1 }}</v-chip>
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ product.product.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ product.noPallets }} Pallets
                                        </v-list-item-subtitle>
                                        <div v-if="binsAndCartons">
                                          <v-list-item-subtitle
                                            style="font-size: 12px"
                                          >
                                            <v-icon small class="mr-1"
                                              >widgets</v-icon
                                            >
                                            {{
                                              calculateProductBins(
                                                product.productId
                                              )
                                            }}
                                            BINS
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle
                                            style="font-size: 12px"
                                          >
                                            <v-icon small class="mr-1"
                                              >widgets</v-icon
                                            >
                                            {{
                                              calculateProductCartons(
                                                product.productId
                                              )
                                            }}
                                            CARTONS
                                          </v-list-item-subtitle>
                                        </div>
                                        <div v-else>
                                          <v-list-item-subtitle
                                            style="font-size: 12px"
                                          >
                                            <v-icon small class="mr-1"
                                              >widgets</v-icon
                                            >
                                            {{
                                              getPackType(selectedContainer) == "BIN"
                                                ? calculateProductBins(
                                                    product.productId
                                                  )
                                                : calculateProductCartons(
                                                    product.productId
                                                  )
                                            }}
                                            {{ getPackType(selectedContainer) + "S" }}
                                          </v-list-item-subtitle>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider> </v-divider>
                                    <v-subheader style="font-size: 16px">
                                      Device(s) </v-subheader
                                    >
                                    <v-divider></v-divider>
                                    <v-progress-linear
                                      color="primary"
                                      indeterminate
                                      v-if="loadingSensor"
                                    ></v-progress-linear>
                                    <v-list dense :key="telemetryKey">
                                        <v-list-item
                                      v-for="device in selectedContainer.telemetry"
                                      :key="device.id"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ device.serial }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          <v-avatar
                                            size="16"
                                            class="mr-1"
                                            v-if="
                                              device.provider == 'SensiTech'
                                            "
                                          >
                                            <img
                                              contain
                                              src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwEKCgkBDRYDDQEBARsIFRAWIB0iIiAdExMkKDQsJCYxJx8fLTEtMTU3Ojo6Iys/RD84QzQ5OisBCgoKDQwBGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Kzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIABAAEAMBIgACEQEDEQH/xAAWAAADAAAAAAAAAAAAAAAAAAAEBQb/xAAnEAAABQMCBQUAAAAAAAAAAAABAgMEBgUHEQBBFCEyUWESFRYjMf/EABQBAQAAAAAAAAAAAAAAAAAAAAT/xAAYEQACAwAAAAAAAAAAAAAAAAABAgAGEf/aAAwDAQACEQMRAD8Av6zWFDleuBnSyNPsI4CFhCbQSUEDuFsh1Kj0FARAOXnOmkdVVXSEVIy8bmh5uCPCbtXSGoAbsJFd/wB0J7Q8IvUGKUn9Le+6/wA3JcyTwIrwuRx60lScsh28b8tGx+hlopnhizApguscHgW3t7AeESSwGPrTyOM76Q5QVnBDIGNg0z//2Q==
                    "
                                            />
                                          </v-avatar>
                                          {{ device.provider }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="
                                        !loadingSensor &&
                                        item.telemetry &&
                                        item.telemetry.length == 0
                                      "
                                    >
                                      <v-list-item-content class="text-center">
                                        <span
                                          style="color: grey; font-size: 12px"
                                          >No devices found</span
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                    </v-list>
                                   
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                              <v-tabs
                                v-model="containerTab"
                                :key="telemetryKey"
                              >
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab href="#summary"> Summary </v-tab>
                                <v-tab
                                  href="#telemetry"
                                  v-if="displayTelemetry"
                                >
                                  Telemetry
                                </v-tab>
                                <v-tab href="#contents"> Contents </v-tab>
                              </v-tabs>
                              <v-tabs-items
                                v-model="containerTab"
                                style="background-color: transparent"
                              >
                                <v-tab-item
                                  value="summary"
                                  style="background-color: transparent"
                                >
                                  <v-card
                                    flat
                                    style="border-radius: 0px"
                                    class="mt-0 pt-0"
                                  >
                                    <v-card-text
                                    >
                                      <v-row class="mt-3">
                                        <v-col cols="12">
                                          <v-card
                                            flat
                                            style="
                                              background: var(
                                                --v-component-base
                                              ) !important;
                                            "
                                          >
                                            <v-subheader style="font-size: 16px"
                                              ><v-icon
                                                color="secondary"
                                                class="mr-2"
                                                >category</v-icon
                                              >
                                              Product Breakdown</v-subheader
                                            >
                                            <v-divider></v-divider>
                                            <v-card-text>
                                              <ProductBreakdownTable
                                                :headers="productSummaryHeaders"
                                                :items="
                                                  selectedContainer.productSummary
                                                "
                                                :tableKey="productKey"
                                                :loading="
                                                  loadingContainerBreakdown
                                                "
                                                :height="'100%'"
                                              />
                                            </v-card-text>
                                          </v-card>
                                          <v-card
                                            flat
                                            style="
                                              background: var(
                                                --v-component-base
                                              ) !important;
                                              border-radius: 20px;
                                            "
                                            class="mt-3"
                                          >
                                            <v-card-title>
                                              <v-icon
                                                color="secondary"
                                                class="mr-2"
                                                >category</v-icon
                                              >
                                              Container Events
                                            </v-card-title>
                                            <v-card-text>
                                              <v-row>
                                                <v-col cols="12" sm="12">
                                                  <ul class="events">
                                                    <li
                                                      v-for="eventData in selectedContainer.containerMilestones"
                                                      :key="eventData.id"
                                                      style="
                                                        list-style-type: none;
                                                      "
                                                      class="my-3"
                                                    >
                                                      <v-avatar
                                                        class="mb-1 mr-2"
                                                        size="10"
                                                        :color="
                                                          eventData.isActual
                                                            ? 'success'
                                                            : 'secondary'
                                                        "
                                                      ></v-avatar>
                                                      <strong
                                                        style="font-size: 14px"
                                                        >{{
                                                          formatDateTime(
                                                            eventData.date
                                                          )
                                                        }}</strong
                                                      >
                                                      <v-chip
                                                        outlined
                                                        style="border: none"
                                                        small
                                                        v-if="
                                                          eventData.location
                                                        "
                                                      >
                                                        <v-avatar
                                                          size="20"
                                                          left
                                                        >
                                                          <v-img
                                                            contain
                                                            :src="`https://cdn.loglive.io/flags/4x3/${eventData.location
                                                              .substring(0, 2)
                                                              .toLowerCase()}.svg`"
                                                          ></v-img>
                                                        </v-avatar>
                                                        {{ eventData.location }}
                                                      </v-chip>
                                                      <span
                                                        class="ml-2"
                                                        style="font-size: 14px"
                                                        >{{
                                                          eventData.description
                                                        }}</span
                                                      >
                                                      <span
                                                        v-if="
                                                          eventData.vesselName
                                                        "
                                                        class="ml-2"
                                                        >-
                                                        {{
                                                          eventData.vesselName
                                                        }}
                                                        {{
                                                          eventData.voyageNo
                                                        }}</span
                                                      >
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style="width: 100%"
                                                    class="text-center"
                                                    v-if="
                                                      selectedContainer.containerMilestones
                                                        .length == 0
                                                    "
                                                  >
                                                    No container events
                                                  </div>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-tab-item>
                                <v-tab-item value="telemetry">
                                  <v-card
                                    flat
                                    style="
                                      background: var(
                                        --v-component-base
                                      ) !important;
                                    "
                                  >
                                    <!-- <v-card-title>
                                      <v-icon color="secondary" class="mr-2"
                                        >thermostat_auto</v-icon
                                      >
                                      Telemetry
                                    </v-card-title> -->
                                    <v-card-text>
                          <div
                            v-if="
                              selectedContainer.telemetry &&
                              selectedContainer.telemetry.length > 0
                            "
                            class="my-0 py-0"
                          >
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px"
                              ><v-icon class="mr-2" color="secondary"
                                >thermostat</v-icon
                              >
                              Monitoring
                            </v-subheader>
                            <v-divider></v-divider>
                            <ContainerTelemetry
                              :data="selectedContainer.telemetry"
                            />
                          </div>
                        </v-card-text>
                                  </v-card>
                                </v-tab-item>
                                <v-tab-item value="contents">
                                  <v-card flat style="border-radius: 0px">
                                    <v-card-text>
                                      <v-row justify="end">
                                        <v-col cols="12" sm="4">
                                          <el-input
                                            ref="searchBar"
                                            suffix-icon="el-icon-search"
                                            v-model="searchPallets"
                                            clearable
                                            placeholder="Search"
                                          >
                                          </el-input>
                                        </v-col>
                                      </v-row>
                                      <v-data-table
                                        :headers="palletHeaders"
                                        :items="selectedContainer.containerPallets"
                                        :search="searchPallets"
                                        dense
                                        fixed-header
                                        disable-pagination
                                        hide-default-footer
                                      >
                                        <!-- <template
                                          v-slot:[`item.line`]="{ item }"
                                        >
                                          {{
                                            uniquePallets.indexOf(item.sscc) + 1
                                          }}
                                        </template> -->
                                      </v-data-table>
                                    </v-card-text>
                                  </v-card>
                                </v-tab-item>
                              </v-tabs-items>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>

                      <v-card-text :key="eventKey" v-if="!loadingContainer">
                        <v-card-text v-if="selectedContainer.events">
                          <!-- <v-divider></v-divider> -->
                          <v-subheader style="font-size: 16px"
                            ><v-icon class="mr-2" color="secondary"
                              >gps_not_fixed</v-icon
                            >
                            Container Tracking</v-subheader
                          >
                          <!-- <v-divider></v-divider> -->
                          <DCSAContainerTracking
                            class="mt-1 pt-1"
                            v-if="selectedContainer.eventType == 'DCSA'"
                            :events="selectedContainer.events"
                            :vertical="false"
                          />
                          <ContainerMilestone
                            class="mt-1 pt-1"
                            v-else
                            :events="selectedContainer.events"
                            :vertical="false"
                          />
                        </v-card-text>
                       
                      </v-card-text>
                    </v-card></v-dialog>
  </div>
</template>
  <script>
//   import BookingModal from "./BookingModal.vue";
import dateFormat from "dateformat";
import draggable from "vuedraggable";
import * as moment from "moment";
import { PDFDocument } from "pdf-lib";
import ProductBreakdownTable from "../SystemComponents/ProductBreakdownTable.vue";
// import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";

export default {
  components: {
    //   BookingModal,
    draggable,
    // VuePdfEmbed,
    ProductBreakdownTable,
    ViewFile,
  },
  data: () => ({
    previewShipmentFile: false,
    allTeams: [],
    booking: {},
    bookings: {
      total: 0,
      data: [],
    },
    bookingTimeout: null,
    bookingQuery: null,
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
    ],
    bookingTypes: [
      { value: "FREIGHT", icon: "mode_of_travel", color: "blue" },
      { value: "LOGISTICS", icon: "mode_of_travel", color: "blue" },
      { value: "DOCUMENTATION", icon: "article", color: "secondary" },
      { value: "HAULAGE", icon: "local_shipping", color: "blue-grey" },
      { value: "MONITORING", icon: "thermostat", color: "teal" },
    ],
    bulkQ67: null,
    columnDrag: false,
    containerHeaders: [
      {
        value: "status",
        text: "Status",
        align: "center",
      },

      {
        value: "ctoNo",
        text: "CTO No.",
        align: "center",
        show: true,
      },
      {
        value: "shipmentFile.fileNumber",
        text: "File",
        align: "center",
        show: true,
      },
      {
        value: "customerContainerRef",
        text: "Customer Ref.",
        align: "center",
        sortable: false,
      },
      {
        text: "Container No.",
        value: "containerNo",
        align: "center",
        sortable: false,
      },
      {
        value: "sealNo",
        text: "Seal No.",
        align: "center",
        sortable: false,
      },
      {
        value: "containerTypeCode",
        text: "Type",
        align: "center",
        sortable: false,
      },
      {
        value: "verificationMethod",
        text: "Method",
        align: "center",
        sortable: false,
      },
      {
        value: "features",
        text: "Features",
        align: "center",
        width: "150px",
        show: true,
        sortable: false,
      },
    ],
    milestoneTypes: [
      {
        name: "ETD",
        value: "ETD",
      },
      {
        name: "ETA",
        value: "ETA",
      },
      {
        name: "Loading Date",
        value: "LOADING_DATE",
      },
    ],
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    containerModal: false,
    containerTab: "Summary",
    createBookingModal: false,
    dateMenu: false,
    dateKey: 750,
    eventKey: 1200,
    displayTelemetry: false,
    downloadingBulkQ67: false,
    filteredResults: [],
    filters: null,
    filteredValues: [],
    finishedProcessingQ67: false,
    finishedProcessingMergedQ67: false,
    fullscreen: false,
    transportStatuses: [
      {
        text: 'On Hold',
        value: 'onHold',
        color: 'brown lighten-1'
      },
      {
        text: "Breakdown",
        value: "BREAKDOWN",
        color: "red",
      },
      {
        text: "Unallocated",
        value: "PENDING",
        color: "orange",
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      // {
      //   text: "Pre-Allocated",
      //   value: "PRE_ALLOCATED",
      //   color: "green darken-2",
      // },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: "Pre-Loaded",
        value: "PRE_LOADED",
        color: "blue-grey darken-2",
      },
      {
        text: "Collect Tomorrow",
        value: "COLLECT_TOMORROW",
        color: "orange darken-2",
      },
      {
        text: 'Collect Later Today',
        value: 'COLLECT_LATER_TODAY',
        color: 'orange darken-2'
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Picked Up",
        value: "GATE_OUT_EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route Gas",
        value: "EN_ROUTE_GAS",
        color: "green lighten-2",
      },
      {
        text: "At Gassing",
        value: "AT_GASSING",
        color: "green lighten-3",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: "Overnight Genset",
        value: "OVERNIGHT_GENSET",
        color: "#363062",
      },
      {
        text: "En route Plug-in",
        value: "EN_ROUTE_PLUG_IN",
        color: "blue lighten-2",
      },
      {
        text: "Plugged-in",
        value: "PLUG_IN",
        color: "blue lighten-3",
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "#183A1D",
      },
      {
        text: "Stacked",
        value: "STACKED",
        color: "success",
      },
      {
        text: "Preadvise Only",
        value: "preadviseOnly",
        color: "pink darken-2",
      },
      {
        text: "Arrange Plugged-In",
        value: "preadviseOnly",
        color: "#F4CE14",
      },
      {
        text: "Cancelled",
        value: "cancelled",
        color: "red darken-2",
      },
      {
        text: undefined,
        value: undefined,
      },
      {
        text: null,
        value: null,
      },
    ],
    headers: [
      // {
      //   text: "Action",
      //   align: "center",
      //   disableSort: true,
      //   disableFilter: true,
      //   value: "actions",
      //   width: "100px",
      // },
      {
        text: "Booking Status",
        value: "state",
        align: "center",
        width: "150px",
        filterable: true,
      },
      // {
      //   text: "Container Status",
      //   value: "status",
      //   align: "center",
      // },
      {
        text: "Transport",
        value: "transportStatus",
        align: "center",
        width: "150px",
        filterable: true,
      },
      {
        text: "User",
        value: "userFullName",
        align: "center",
        width: "100px",
        filterable: true,
      },
      {
        text: "Order No",
        value: "orderNo",
        align: "center",
        filterable: true,
      },
      {
        text: "CTO No",
        value: "ctoNo",
        align: "center",
        filterable: true,
      },
      {
        text: "Container No.",
        value: "containerNo",
        align: "center",
        filterable: true,
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
        align: "center",
        width: "200px",
      },
      {
        text: "Shipper",
        value: "shipperName",
        width: "220px",
        filterable: true,
      },
      {
        text: "Consignee",
        value: "consigneeName",
        width: "220px",
        filterable: true,
      },
      // {
      //   text: "Customer",
      //   value: "customerName",
      //   width: "220px",
      //   filterable: true,
      // },
      {
        text: "Carrier",
        value: "shippingLineName",
        align: "left",
      },
      {
        text: "Transp Co.",
        value: "transportCoordinator",
        align: "center",
      },
      {
        text: "Depot",
        value: "depot",
        align: "center",
      },
      {
        text: "LP-1",
        value: "lp1",
        align: "center",
      },
      {
        text: "LP-2",
        value: "lp2",
        align: "center",
      },
      {
        text: "Plug-in-depot",
        value: "PluggedInDepot",
        align: "center",
      },
      {
        text: "Features",
        value: "features",
        align: "center",
        width: "220px",
      },
      {
        text: "Loading date",
        value: "loadingDate",
        align: "center",
      },
      {
        text: "Carrier Ref",
        value: "carrierReferenceNumber",
        align: "center",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
        width: "100px",
      },
      {
        text: "ETD",
        value: "etd",
        align: "center",
        width: "100px",
      },
      {
        text: "Dest.",
        value: "finalDestinationValue",
        align: "center",
        width: "100px",
      },
    ],
    quickSelectDates: [
      {
        text: "Today",
        type: "current",
        value: "today",
        difference: 0,
      },
      {
        text: "Current 3 Days",
        type: "current",
        value: "current_3day",
        difference: 0,
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
    ],
    imageLoading: false,
    importExport: 0,
    item: {},
    loading: false,
    loadingClone: false,
    loadingContainer: false,
    loadingContainerBreakdown: false,
    loadingContainers: false,
    loadingOrganisations: false,
    loadingSaveCustom: false,
    loadingSensor: false,
    loadingTeams: false,
    myTeams: [],
    organisations: {
      total: 0,
      data: [],
    },
    params: null,
    palletHeaders: [
      {
        text: "Line",
        value: "line",
        sortable: false,
        width: "5px",
        align: "center",
      },
      {
        text: "SSCC / Pallet ID",
        value: "sscc",
        sortable: false,
        align: "center",
      },
      {
        text: "Producer/ PUC(s)",
        value: "farm",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        sortable: false,
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        sortable: false,
        align: "center",
      },
      {
        text: "Production Area",
        value: "productionArea",
        sortable: false,
        align: "center",
      },
      {
        text: "Commodity",
        value: "commodity",
        sortable: false,
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        sortable: false,
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        sortable: false,
        align: "center",
      },
      {
        text: "Class/ Grade",
        value: "gradeClassCode",
        width: "20px",

        sortable: false,
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "20px",
        sortable: false,
        align: "center",
      },
      {
        text: "Pack Type",
        value: "packType",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Quantity",
        value: "noCartons",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Nett Weight (KG)",
        value: "nettWeight",
        sortable: false,
        align: "right",
      },
      {
        text: "Gross Weight (KG)",
        value: "grossWeight",
        sortable: false,
        align: "right",
      },

      {
        text: "PPECB Inspection Date",
        value: "inspectionDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        sortable: false,
        align: "center",
      },
      {
        text: "Inspection Point",
        value: "inspectionPoint",
        sortable: false,
        align: "center",
      },
    ],
    previewModal: false,
    processingQ67: false,
    processingMergedQ67: false,
    productKey: 1300,
    productSummaryHeaders: [
      {
        text: "Product",
        value: "productName",
        align: "center",
      },
      {
        text: "Variety",
        value: "varietyName",
        align: "center",
      },
      {
        text: "Pack Code",
        value: "packCode",
        align: "center",
      },
      {
        text: "Grade/Class",
        value: "gradeClassCode",
        align: "center",
      },

      {
        text: "Count/Size",
        value: "countSize",
        align: "center",
      },
      {
        text: "Pallet Qty",
        value: "palletQuantity",
        align: "center",
      },
      {
        text: "No. Cartons",
        value: "noCartons",
        align: "center",
      },
      {
        text: "Percentage",
        value: "percentage",
        align: "center",
      }
    ],
    q67CommentModal: false,
    q67Comment: null,
    q67Request: {
      ppecbInv: "forwarder",
    },
    q67Modal: false,
    q67UpdateKey: 450,
    refreshBooking: 1050,
    search: null,
    searchHeaders: {},
    searchKey: 100,
    searchPallets: null,
    selectAllBookings: false,
    selectedBookings: [],
    selectedBooking: {},
    selectedBookingModal: false,
    selectedContainer: null,
    selectedDate: null,
    selectedHeaders: [],
    selectedBookingItem: {},
    settingsChange: false,
    sensorData: [],
    selectedTeams: [],
    selectedWeek: null,
    showMenu: false,
    sortBy: null,
    sortDesc: false,
    summaryItems: [],
    telemetryKey: 1100,
    updateFilterValue: 350,
    userLayout: null,
    x: null,
    y: null,
  }),
  watch: {
    selectAllBookings(val) {
      if (val) {
        this.selectedBookings = this.displayData;
      } else {
        this.selectedBookings = [];
      }
    },
    showMenu(val) {
      if (!val) {
        this.selectedBookingItem = {};
      }
    },
    //   'params.selectedDate':{
    //   immediate: true,
    //   handler(val) {
    //     if (val) {

    //     }
    //   }},
  },
  beforeCreate() {
    if (localStorage.bookingContainerParams) {
      this.params = JSON.parse(localStorage.bookingContainerParams);
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
    }
    // if(localStorage['bookingFilters_'+this.$store.state.currentOrg.id]){
    //   this.filters = JSON.parse(localStorage['bookingFilters_'+this.$store.state.currentOrg.id])
    //   let result = [];
    //   let keys = Object.keys(this.filters);
    //   keys.forEach((x) => {
    //     result = [
    //       ...result,
    //       ...this.filters[x].map((y) => ({
    //         field: x,
    //         fieldName: this.headers.find((z) => z.value == x).text,
    //         value: y,
    //       })),
    //     ];
    //   });
    //   this.filteredValues = result;
    // }
  },
  created() {
    if (localStorage.bookingContainerParams) {
      this.params = JSON.parse(localStorage.bookingContainerParams);
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
      this.params.queryType = "ShipmentOverview";
    }
    if (
      localStorage[
        "overviewBookingContainerFilters_" + this.$store.state.currentOrg.id
      ]
    ) {
      this.filters = JSON.parse(
        localStorage[
          "overviewBookingContainerFilters_" + this.$store.state.currentOrg.id
        ]
      );
      // console.log("existing filters", this.filters)
      if (typeof this.filters != "object") {
        // this.filters = {}
        this.resetFilters();
      }
      let result = [];
      let keys = Object.keys(this.filters);
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
    }
    if (!this.params) {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_week",
        dates: [],
      };
      this.params.queryType = "ShipmentOverview";
    }
    if (!this.params.selectedDate) {
      this.selectDate("current_week");
    } else {
      this.selectDate(this.params.selectedDate);
    }
    if (!this.filters) {
      this.filters = {};
      this.resetFilters();
    }
  },
  async mounted() {
    //   await this.getTeams();
    this.load();
    this.getUserLayout();
    // this.resetFilters();
  },
  computed: {
    binsAndCartons() {
      let result = false;
      if (this.selectedContainer && this.selectedContainer.containerPallets) {
        let mapped = this.selectedContainer.containerPallets.map((x) => x.packType);
        if (mapped.includes("BIN") && mapped.includes("CARTON")) {
          result = true;
        }
      }
      return result;
    },
    
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.63 + "px";
    },
    // countContainers() {

    //   return this.filteredResults
    //     .map((x) => x.numContainers)
    //     .reduce((a, b) => a + b, 0);
    // },
    countContainers() {
      // Assuming there's a way to check if a filter is applied.
      // This could be a boolean flag or a check based on the contents of `filteredResults`.
      // Replace `isFilterApplied` with the actual condition you use to determine if a filter is active.
      const searchActive = this.search && this.search.length > 0;

      const dataToUse = searchActive ? this.displayData : this.filteredResults;

      return dataToUse.map((x) => x.numContainers).reduce((a, b) => a + b, 0);
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.lp1 &&
              x.lp1.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.lp2 &&
              x.lp2.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.PluggedInDepot &&
              x.PluggedInDepot.toLowerCase().includes(
                this.search.toLowerCase()
              )) ||
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase()))
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    tableWidth() {
      let result = 0;
      this.selectedHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  methods: {
    calculateProductCartons(productId) {
      let pallets = this.selectedContainer.containerPallets.filter(
        (x) => x.productId == productId && x.packType == "CARTON"
      );
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductBins(productId) {
      let bins = this.selectedContainer.containerPallets.filter(
        (x) => x.productId == productId && x.packType == "BIN"
      );
      return bins.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    viewShipmentFile(file) {
      this.selectedFile = file;
      this.previewKey++;
      this.previewShipmentFile = true;
    },
    popupFileStauts(file) {
      let arr = [];

      for (let x of file) {
        let obj = {};
        if (x.status == "Processed" || x.status == "Ready") {
          obj.text = x.total + " - " + x.fileNumber;
          obj.color = "#99B080";
        } else if (x.status == "Draft") {
          obj.text = x.total + " - " + x.fileNumber;
          obj.color = "orange";
        } else {
          obj.text = x.total + " - " + x.status;
          obj.color = "#FF6969";
        }
        arr.push(obj);
      }

      return arr;
    },
    checkFileStatus(file) {
      if (file.length > 0) {
        const allProcessedOrReady = file.every(
          (item) => item.status === "Ready" || item.status === "Processed"
        );
        return allProcessedOrReady ? "READY" : "OUTSTANDING";
      } else {
        return "No Containers";
      }
    },

    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      localStorage.setItem(
        "overviewBookingContainerFilters_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.filters)
      );
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllBookings = false;
      this.selectedBookings = [];
      this.calculateFilteredResults();
    },
  
    changeDateType(name) {
      console.log("name coming in", name);
      if (name == "ETD") {
        this.params.dateType = "ETD";
      } else if (name == "ETA") {
        this.params.dateType = "ETA";
      } else if (name == "LOADING_DATE") {
        this.params.dateType = "LOADING DATE";
      }
      this.dateKey++;
      this.getBookings();
    },
    changeImportExportFilter(movementType) {
      if (movementType == "ALL") {
        this.importExport = 0;
        this.filters.movementType = [];
      } else {
        this.filters.movementType = [movementType];
        this.importExport = movementType == "EXPORT" ? 1 : 2;
      }
      this.calculateFilteredResults();
      this.updateFilterValue++;
    },
    bookingTypeColor(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.color : null;
    },
    bookingTypeIcon(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.icon : null;
    },
    calculateFilteredResults() {
      let filterKeys = Object.keys(this.filters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.bookings.data];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    containerGrossWeight(container) {
      return container.containerPallets
        .map((item) => Math.round(parseFloat(item.grossWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
    containerNettWeight(container) {
      return container.containerPallets
        .map((item) => Math.round(parseFloat(item.nettWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.selectAllBookings = false;
      this.selectedBookings = [];
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() === item.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    getPackType(item) {
      let type = item.containerPallets.find((x) => x.packType);
      return type ? type.packType : "CARTONS";
    },
    transportStatusColor(status) {
      return this.transportStatuses.find((x) => x.text == status)?.color;
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getBookings() {
      if (this.bookingTimeout) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loading && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loading = true;
      let params = { ...this.params };
      params.teams = this.selectedTeams;
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.selectedBookings = [];
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        this.bookings = await this.$API.getBookingContainerOverviews({
          params: params,
          signal,
        });
        // console.log('this.bookings', this.bookings)
        this.calculateFilteredResults();
        this.loading = false;
      }, 500);
    },
    // async getBookingContainers() {
    //   this.loadingContainers = true;
    //   this.selectedBooking.bookingContainers =
    //     await this.$API.getBookingContainersBasic(this.selectedBooking.id);
    //   this.loadingContainers = false;
    // },
    async getRelations() {
      this.loadingOrganisations = true;
      this.organisations = await this.$API.getRelation({
        // params: this.params,
      });
      this.loadingOrganisations = false;
    },
    async getUserLayout() {
      this.selectedHeaders = this.headers;
      this.userLayout = await this.$API.getUserTableLayout({
        params: {
          module: "Bookings_Containers",
        },
      });
      if (this.userLayout && this.userLayout.config) {
        this.selectedHeaders = this.userLayout.config.filter((x) =>
          this.headers.map((y) => y.value).includes(x.value)
        );
      } else {
        this.selectedHeaders = this.headers;
      }
    },
    async getTeams() {
      this.loadingTeams = true;
      this.allTeams = await this.$API.getTeams();
      this.myTeams = await this.$API.getUserTeams();
      if (localStorage["bookingTeams_" + this.$store.state.currentOrg.id]) {
        this.selectedTeams = JSON.parse(
          localStorage["bookingTeams_" + this.$store.state.currentOrg.id]
        );
      } else {
        this.selectedTeams = this.myTeams.map((x) => x.teamId);
      }
      this.loadingTeams = false;
    },
    containerProducts(item) {
      let result = [];
      if (item && item.containerPallets) {
        let uniqueProducts = [
          ...new Set(
            item.containerPallets.map((x) => x.productId).filter(Boolean)
          ),
        ];
        for (let i = 0; i < uniqueProducts.length; i++) {
          let product = item.containerProducts.find(
            (x) => x.productId == uniqueProducts[i]
          );
          let obj = {
            productId: uniqueProducts[i],
            product: product.product,
            productTree: product.productTree,
            noPallets: [
              ...new Set(
                item.containerPallets
                  .filter((x) => x.productId == uniqueProducts[i])
                  .map((x) => x.sscc)
              ),
            ].length,
            noCartons: item.containerPallets
              .filter((x) => x.productId == uniqueProducts[i])
              .map((x) => parseInt(x.noCartons))
              .reduce((a, b) => a + b, 0),
          };
          result.push(obj);
        }
        result.sort((a, b) =>
          a.product.name > b.product.name
            ? 1
            : b.product.name > a.product.name
            ? -1
            : 0
        );
      }
      return result;
    },
    async getContainerProductSummary(item) {
      this.loadingContainerBreakdown = true;
      let result = await this.$API.getContainerSummary(
        item.shipmentId,
        item.id
      );
      item.productSummary = result;
      this.selectedContainer.productSummary = item.productSummary.map((x) => ({
        ...x,
        cartonPrice: "?",
        kgPrice: "?",
      }));
      this.loadingContainerBreakdown = false;
    },
    async getContainerTelemetry(item) {
      item.telemetry = [];
      this.displayTelemetry = false;
      let result = await this.$API.getContainerTelemetryData(item.id);
      item.telemetry = result;
      this.displayTelemetry = item.telemetry.length > 0
      this.telemetryKey++;
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.bookings.data
            // .filter(x => x[value])
            .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      // if (value == 'status') {
      //     result.push({ name: 'Unallocated', value: null })
      // }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    async load() {
      this.getBookings();
      await this.getRelations();
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.filteredValues = [];
      this.selectedBookings = [];
      this.selectAllBookings = false;
      this.updateFilterValue++;
      localStorage.removeItem(
        "overviewBookingContainerFilters_" + this.$store.state.currentOrg.id
      );
      this.calculateFilteredResults();
    },
    async saveCustomHeaders() {
      this.loadingSaveCustom = true;
      if (this.userLayout && this.userLayout.id) {
        let result = await this.$API.updateUserTableLayout({
          id: this.userLayout.id,
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully updated!");
      } else {
        let result = await this.$API.createUserTableLayout({
          module: "Bookings_Containers",
          config: this.selectedHeaders,
        });
        this.settingsChange = false;
        this.userLayout = result;
        this.userLayout.config = this.selectedHeaders;
        this.$message.success("Successfully saved setup!");
      }
      this.loadingSaveCustom = false;
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      // console.log("Setting params", JSON.stringify(this.params));
      localStorage.setItem(
        "bookingContainerParams",
        JSON.stringify(this.params)
      );
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem(
        "bookingContainerParams",
        JSON.stringify(this.params)
      );
      this.getBookings();
      this.dateMenu = false;
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getBookings();
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    selectBooking(item) {
      let find = this.selectedBookings.find((x) => x.id == item.id);
      if (!find) {
        this.selectedBookings.push(item);
      } else {
        this.selectedBookings = this.selectedBookings.filter(
          (x) => x.id != item.id
        );
      }
    },
    selectAllTeams() {
      this.selectedTeams = this.allTeams.map((x) => x.id);
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.getBookings();
    },
    selectTeam(id) {
      let findIndex = this.selectedTeams.indexOf(id);
      if (findIndex > -1) {
        this.selectedTeams.splice(findIndex, 1);
      } else {
        this.selectedTeams.push(id);
      }
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.getBookings();
    },
    async selectContainer(item) {
      this.selectedContainer = item;
      this.loadingContainer = true;
      this.containerTab = "summary";
    //   this.loading = true;
      this.selectedContainer = await this.$API.getContainer(item.id);
      // console.log(this.selectedContainer)
    //   this.loading = false;
    this.containerModal = true;

      // this.eventType = null;
      this.sensorData = [];
      if (!item.productSummary) {
        this.selectedContainer.productSummary = [];
        await this.getContainerProductSummary(item);
        this.productKey++;
      }
      if (!item.telemetry) {
        item.telemetry = [];
        await this.getContainerTelemetry(item);
      }
      this.loadingBreakdown = true;
      this.summaryItems = await this.$API.getContainerSummary(
        this.selectedContainer.shipmentId,
        this.selectedContainer.id
      );
      this.loadingBreakdown = false;
      this.loadingContainer = false;
    },
    async downloadBookingData() {
      console.log("this.selectedBookings", this.selectedBookings);
      let contianerIds = this.selectedBookings.map((x) => x.id);
      let obj = {
        containerIds: contianerIds,
      };
      let response = await this.$API.getBookingBulkDownload(obj);

      if (response && response.dataUri) {
        const link = document.createElement("a");
        link.href = response.dataUri;

        // Update the 'download' attribute to reflect Excel file extension (.xlsx)
        link.setAttribute("download", `LoadsReport.xlsx`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("No data received from server");
      }
    },

    toggleColumn(item) {
      if (item.show === true) {
        this.headers.find((header) => header.value === item.value).show = true;
      } else {
        this.headers.find((header) => header.value === item.value).show = false;
      }
      this.selectedHeaders = this.headers.filter((x) => x.show);
    },
    updateSearchKey() {
      this.searchKey++;
    },
    updateUserLayout() {
      this.settingsChange = true;
    },
    viewBooking(item) {
      const url = "/booking/" + item.systemReference;
      window.open(url, "_blank");
    }
  },
};
</script>
  <style scoped>
.container {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  color: #004225;
}

.orange {
  color: orange;
}

.red {
  color: #200e3a;
}

/* th div {
        border: 0;
        min-height: 20px;
        min-width: 20px;
    } */
</style>